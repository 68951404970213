import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'
import store from '@/store'
import gainSuccessMore from "./gainSuccess";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/service-fee',
    name: 'ServiceFeeBuy',
    component: () =>
        import(/* webpackChunkName: "login" */ '../views/serviceFee/ServiceFeeBuy.vue'),
  },
  {
    path: '/service-feePay-sure',
    name: 'ServiceFeePaySure',
    component: () =>
        import(/* webpackChunkName: "login" */ '../views/serviceFee/ServiceFeePaySure.vue'),
  },
  {
    path: '/settlement',
    name: 'Settlement',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/reportFee/Settlement.vue'),
  },
  {
    path: '/paySure',
    name: 'PaySure',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/reportFee/PaySure.vue'),
  },
  {
    path: '/FeeRecord',
    name: 'FeeRecord',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/reportFee/FeeRecord.vue'),
  },
  {
    path: '/payComplate',
    name: 'PayComplate',
    component: () =>
      import(/* webpackChunkName: "login" */ '../components/payComment/payComplate.vue'),
  },
  {
    path: '/getAuthUrl',
    name: 'GetAuthUrl',
    component: () =>
      import(/* webpackChunkName: "login" */ '../components/payComment/getAuthUrl.vue'),
  },
  {
    path: '/noData',
    name: 'NoData',
    component: () =>
      import(/* webpackChunkName: "login" */ '../components/noData.vue'),
  },
  {
    path: '/',
    name: 'Drainage',
    alias: '/drainage',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/landPage/drainage.vue'),
  },
  {
    path: '/receiptCollect',
    name: 'ReceiptCollect',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/collect/receiptCollect.vue'),
  },
  {
    path: '/classPurchase',
    name: 'ClassPurchase',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/landPage/classPurchase/classPurchase.vue'),
  },
  {
    path: '/gainSuccess',
    name: 'GainSuccess',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/gainSuccess/index.vue'),
    children: gainSuccessMore
  },
  {
    path: '/companyLandpage',
    name: 'CompanyLandpage',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/landPage/company/index.vue'),
  },
  {
    path: '/student/downPage',
    name: 'StudentDownPage',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/student/downPage.vue'),
  },
  {
    path: '/order/paysign',
    name: 'OrderPaySign',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/order/PaySign.vue'),
  },
  {
    path: '/student/status-info/:userId?',
    name: 'StudentStatusInfo',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/student/StatusInfo.vue'),
    meta: {
      title: "我的资料"
    }
  },
  {
    path: '/student/study-report',
    name: 'StudentStudyReport',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/student/StudyReport.vue'),
    meta: {
      title: "学习报告"
    }
  },
  {
    path: '/student/study-report/2023',
    name: 'StudentStudyReport2023',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/student/StudyReport2023.vue'),
    meta: {
      title: "学习报告"
    }
  },
  {
    path: '/postgraduate/report/2023',
    name: 'PostgraduateReport2023',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/student/PostgraduateReport2023.vue'),
    meta: {
      title: "学习报告"
    }
  },
  {
    path: '/student/appraise',
    name: 'StudentAppraise',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/student/Appraise.vue'),
    meta: {
      title: "评价详情"
    }
  },
  {
    path: '/active/activityPoster',
    name: 'ActivityPoster',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/active/activityPoster.vue'),
    meta: {
      title: "哈德教育疯狂双十二"
    }
  },
  {
    path: '/active/graduate',
    name: 'ActivityGraduate',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/active/graduate.vue'),
    meta: {
      title: "毕业季，一起拿证"
    }
  },
  {
    path: '/active/activity-all-marketing',
    name: 'activityAllMarketing',
    component: () =>
        import(/* webpackChunkName: "login" */ '../views/active/activityAllMarketing.vue'),
    meta: {
      title: "哈德助学惠民计划"
    }
  },
  {
    path: '/my_class_file',
    name: 'MyClassFile',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/course/MyClassFile.vue'),
    meta: {
      title: "班级课件"
    }
    
  },
  {
    path: '/preview_pdf',
    name: 'PreviewPdf',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/course/PreviewPdf.vue'),
    meta: {
     title: "pdf预览" 
    }
    
  },
  {
    path: '/student/admissioni-ticket',
    name: 'AdmissionTicket',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/student/AdmissionTicket.vue'),
    meta: {
     title: "准考证" 
    }
  },
  {
    path: '/certificate/list',
    name: 'myCertificate',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/myCertificate.vue'),
    meta: {
     title: "我的证书" 
    }
    
  },
  {
    path: '/certificate/reservation_self',
    name: 'reservationSelf',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/reservationSelf.vue'),
    meta: {
     title: "本人校区领取" 
    }
    
  },
  {
    path: '/certificate/info',
    name: 'certificateInfo',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/certificateInfo.vue'),
    meta: {
     title: "证书详情" 
    }
  },
  {
    path: '/certificate/receive_type',
    name: 'receiveType',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/receiveType.vue'),
    meta: {
     title: "选择领取方式" 
    }
  },
  {
    path: '/certificate/reservation_success',
    name: 'reservationSuccess',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/reservationSuccess.vue'),
    meta: {
     title: "预约成功" 
    }
  },
  {
    path: '/certificate/reservation_other',
    name: 'reservationOtherInfo',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/reservationOtherInfo.vue'),
    meta: {
     title: "他人校区代领" 
    }
  },
  {
    path: '/certificate/reservation_other_time',
    name: 'reservationOtherTime',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/reservationOtherTime.vue'),
    meta: {
     title: "他人校区代领" 
    }
  },
  {
    path: '/certificate/reservation_mail',
    name: 'reservationMail',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/reservationMail.vue'),
    meta: {
     title: "邮寄领取证书" 
    }
  },
  {
    path: '/certificate/reservation_entrust',
    name: 'reservationEntrust',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/certificate/reservationEntrust.vue'),
    meta: {
     title: "委托书" 
    }
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title as unknown as string;
  } else {
    document.title = "哈德网校"
  }
  if (from.name && from.name != 'Login' && from.name != 'NoData') {
    store.commit('setRoute', {
      name: from.name,
      query: { ...from.query }
    })
  }
  next()
});

export default router
